const StaffData = [
    {
        sImage : 'staff/01.jpg',
        lorem : 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Sapiente, atque!',
        sName : 'Nill Smith',
        sPosition : 'Financial Director'
    },

    {
        sImage : 'staff/02.jpg',
        lorem : 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Sapiente, atque!',
        sName : 'Merry Kinnly',
        sPosition : 'Chief Accountant'
    },

    {
        sImage : 'staff/03.jpg',
        lorem : 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Sapiente, atque!',
        sName : 'Frank Kinney',
        sPosition : 'Sales Manager',
    }
]
export default StaffData