import React from 'react'
import Footer from '../Footer/Footer'
import Navbar from '../Navbar/Navbar'
import Team from '../Team/Team'
import Work from '../Work/Work'

const About_ = () => {
  return (
    <>
      <>
    <Navbar/>
    <Work/>
    <Team/>
    <Footer/>
    </>
    </>
  )
}

export default About_