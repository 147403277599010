import React from 'react';
import './Footer.css'

function Footer() {
  return(
  <>
    <footer className='footer'>
    <div className='foot-text'>
    <h1>
            Developed by Ammar Rashid 
        </h1><br />
        <a href="https://ammar.tech">Back to : ammar.tech</a>
      
    </div>  
        
    </footer>
    
  </>
  )
}

export default Footer;
