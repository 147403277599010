const Sdata = [
  {
    Icon: 'fa-solid fa-ankh',
    Heading: 'Truss Systems',
    Text: 'Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt',
  },

  {
    Icon: 'fa-solid fa-microchip',
    Heading: 'Technology',
    Text: 'Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt',
  },

  {
    Icon: 'fa-solid fa-recycle',
    Heading: 'Materials',
    Text: 'Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt',
  },
  {
    Icon: 'fa-solid fa-industry',
    Heading: 'Manufacturing',
    Text: 'Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt',
  },
];
export default Sdata;
