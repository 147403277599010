const SysData = [

    {
        heading : 'About Us',
        text : 'Mauris pharetra et ultrices neque ornare aenean euismod. Habitant morbi tristique senectus et netus et malesuada fames. Ut faucibus pulvinar elementum integer enim neque volutpat.',
        image : 'system/01.jpg'
    },
    {
        heading : 'Our Strategy',
        text : 'Mauris pharetra et ultrices neque ornare aenean euismod. Habitant morbi tristique senectus et netus et malesuada fames. Ut faucibus pulvinar elementum integer enim neque volutpat.',
        image : 'system/02.jpg'
    },

    {
        heading : 'Our Mission',
        text : 'Mauris pharetra et ultrices neque ornare aenean euismod. Habitant morbi tristique senectus et netus et malesuada fames. Ut faucibus pulvinar elementum integer enim neque volutpat.',
        image : 'system/03.jpg'
    },


]
export default SysData